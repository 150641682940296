import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';
import Tappable from '@comp/Buttons/Tappable';
import s from './CardPanel.scss';

const cx = classNames.bind(s);

const CardPanel = ({
  title,
  titleElement,
  description,
  children,
  contentClassName,
  tappableHeading,
  onHeadingClick,
  className,
  verticalDescription
}) => {
  const content = children ? (
    <div
      className={cx({ content: true, [contentClassName]: contentClassName })}
    >
      {children}
    </div>
  ) : null;

  const desc = description ? (
    <span className={s.description}>{description}</span>
  ) : null;

  const header = (
    <header
      className={cx({
        header: true,
        test__header: true,
        verticalDescription
      })}
    >
      {React.createElement(
        titleElement || 'h2',
        { className: `${s.heading} test__name` },
        title
      )}
      {desc}
    </header>
  );

  return (
    <article className={cx({ container: true, [className]: className })}>
      {tappableHeading ? (
        <Tappable
          onClick={onHeadingClick}
          className={cx({
            itemButton: true
          })}
        >
          {header}
        </Tappable>
      ) : (
        header
      )}
      {content}
    </article>
  );
};

CardPanel.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleElement: PropTypes.string,
  children: PropTypes.node,
  contentClassName: PropTypes.string,
  tappableHeading: PropTypes.bool,
  verticalDescription: PropTypes.bool,
  onHeadingClick: PropTypes.func,
  className: PropTypes.string
};

export default CardPanel;
