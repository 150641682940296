import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const TrophyWithCircleIcon = ({ className }) => (
  <svg
    className={cx({ [className]: className })}
    width="114"
    height="114"
    viewBox="0 0 114 118"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M57 0c32.585 0 59 26.415 59 59s-26.415 59-59 59S-2 91.585-2 59 24.415 0 57 0zm0 4C26.624 4 2 28.624 2 59s24.624 55 55 55 55-24.624 55-55S87.376 4 57 4zm0 80.5c8.008 0 14.5 3.967 14.5 9.203 0 3.169-5.092 4.697-13.634 4.792L57 98.5l-.866-.005C47.592 98.4 42.5 96.872 42.5 93.703c0-5.236 6.492-9.203 14.5-9.203zm.146 2.954c-6.493 0-11.6 3.001-11.6 6.34.098.081.256.203.49.334.56.311 1.438.603 2.567.848 2.24.484 5.323.751 8.543.751 3.22 0 6.303-.267 8.542-.751 1.13-.245 2.007-.537 2.567-.848.235-.13.392-.253.472-.35.019-3.323-5.088-6.324-11.581-6.324zM75.659 24.5c2.673 0 4.841 2.19 4.841 4.887v32.112c0 .695-.145 1.377-.425 2.005C77.925 73.36 68.019 80.5 56.5 80.5c-11.52 0-21.427-7.142-23.576-17l-.111-.271a4.917 4.917 0 0 1-.313-1.73V29.387c0-2.699 2.165-4.887 4.841-4.887h38.318zm0 2.891H37.341l-.147.006a1.985 1.985 0 0 0-1.824 1.99v32.112l.009.191c.023.252.094.494.207.717l.072.143.033.158.07.32c1.909 8.371 10.545 14.58 20.739 14.58 10.193 0 18.829-6.208 20.74-14.578l.101-.477.073-.144.077-.17c.091-.232.14-.482.14-.74V29.387l-.006-.15a1.983 1.983 0 0 0-1.966-1.846zM85 32.5c7.46 0 13.5 6.272 13.5 14s-6.04 14-13.5 14h-.5v-3.382h.5c5.632 0 10.206-4.75 10.206-10.618S90.632 35.882 85 35.882h-.5V32.5h.5zm-56.5 0v3.382H28c-5.632 0-10.206 4.75-10.206 10.618S22.368 57.118 28 57.118h.5V60.5H28c-7.46 0-13.5-6.272-13.5-14s6.04-14 13.5-14h.5z"
    />
  </svg>
);

TrophyWithCircleIcon.propTypes = {
  className: PropTypes.string
};

export default TrophyWithCircleIcon;
