import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import strex from './logo-payment-h-24-strex@3x.png';

const StrexIcon = ({ className }) => (
  <img className={cx({ [className]: className })} src={strex} alt="strex" />
);

StrexIcon.propTypes = {
  className: PropTypes.string
};

export default StrexIcon;
