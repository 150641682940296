import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const VippsIcon = ({ className }) => (
  <svg
    className={cx({ [className]: className })}
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="25"
    viewBox="0 0 36 25"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-397 -533) translate(397 533.519)">
          <rect
            width="36"
            height="23.756"
            fill="#FF5B24"
            fillRule="nonzero"
            rx="3.5"
          />
          <rect
            width="31.5"
            height="20.044"
            x="2.25"
            y="1.485"
            fill="#FF5B24"
            fillRule="nonzero"
            rx="10.022"
          />
          <path
            fill="#FFF"
            d="M20.898 9.103c1.033 0 1.918-.776 1.918-1.891 0-1.116-.885-1.892-1.918-1.892-1.032 0-1.917.776-1.917 1.892 0 1.115.885 1.891 1.917 1.891zm2.508 3.057c-1.279 1.648-2.631 2.788-5.016 2.788-2.433 0-4.327-1.455-5.802-3.589-.59-.873-1.5-1.067-2.163-.606-.615.436-.762 1.358-.197 2.158 2.04 3.08 4.868 4.875 8.162 4.875 3.024 0 5.385-1.455 7.228-3.88.689-.898.664-1.82 0-2.329-.614-.485-1.524-.315-2.212.583z"
          />
        </g>
      </g>
    </g>
  </svg>
);

VippsIcon.propTypes = {
  className: PropTypes.string
};

export default VippsIcon;
