import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const ScaleWithCircleIcon = ({ className }) => (
  <svg
    className={cx({ [className]: className })}
    width="114"
    height="114"
    viewBox="0 0 114 118"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M57 0c32.585 0 59 26.415 59 59s-26.415 59-59 59S-2 91.585-2 59 24.415 0 57 0zm0 4C26.624 4 2 28.624 2 59s24.624 55 55 55 55-24.624 55-55S87.376 4 57 4zm27.8 22.5c2.597 0 4.7 2.125 4.7 4.745v50.51c0 2.62-2.103 4.745-4.7 4.745H30.2c-2.597 0-4.7-2.125-4.7-4.745v-50.51c0-2.62 2.103-4.745 4.7-4.745h54.6zm0 3.547H30.2c-.61 0-1.113.47-1.174 1.075l-.006.123v50.51c0 .622.465 1.13 1.06 1.192l.12.006h54.6c.61 0 1.113-.47 1.174-1.075l.006-.123v-50.51c0-.622-.465-1.13-1.06-1.192l-.12-.006zM73.5 34.5v21H58.371l-.135.206-.293-.206H41.5v-21h32zm-3.48 3.553H44.98v13.894l11.454-.001 6.33-9.652 2.92 2.053-4.983 7.599h9.319V38.054z"
    />
  </svg>
);

ScaleWithCircleIcon.propTypes = {
  className: PropTypes.string
};

export default ScaleWithCircleIcon;
