import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { START_SEARCH_THRESHOLD } from '@src/utils/constants/search';
import localize from '@src/i18n';
import ClearButton from '@comp/Buttons/ClearButton';
import Input from '@comp/Forms/Input';
import MagnifyIcon from '@comp/Icons/MagnifyIcon';
import s from './SearchField.scss';

const TypeMore = () => (
  <div className={s.typeMore}>
    {localize('label_recipe_searchbar_validation')}
  </div>
);

const allRecipesTag = 'all_recipes';

class SearchField extends Component {
  onClear = () => {
    const { clearSearchResult, clearSearchInputValue, mealType } = this.props;

    this.setInputFocus();
    clearSearchResult(mealType);
    clearSearchInputValue();
  };

  setInputFocus = () => {
    const { id } = this.props;

    document.getElementById(id).focus();
  };

  handleChange = event => {
    const newValue = event.target.value;
    const {
      getSearchResult,
      clearSearchResult,
      setSearchInputValue,
      isFoodstuffFilter,
      isRecipeFilter,
      maxResult,
      mealType,
      isFeatured,
      product,
      rawSearchInputValue
    } = this.props;

    setSearchInputValue(newValue);

    if (!newValue) {
      clearSearchResult(mealType, product);
    } else if (newValue.length >= START_SEARCH_THRESHOLD) {
      getSearchResult({
        searchString: newValue,
        rawSearchString: rawSearchInputValue,
        isFoodstuffFilter,
        isRecipeFilter,
        maxResult,
        mealType,
        isFeatured,
        recipeSearchPage: 1,
        product,
        filters: `name=${newValue}${
          mealType && mealType !== allRecipesTag
            ? `&mealType=${localize(`label_${mealType}`)}`
            : ''
        }`
      });
    }
  };

  render() {
    const { id, searchInputValue, placeholder, minCharacters } = this.props;

    const showHint =
      minCharacters && searchInputValue && searchInputValue.length === 1;

    return (
      <div className={s.container}>
        <Input
          id={id}
          name={id}
          type="text"
          expand
          inputClassName={s.input}
          input={{
            value: searchInputValue,
            onChange: this.handleChange,
            autoComplete: 'off',
            placeholder
          }}
        />
        <MagnifyIcon onClick={this.setInputFocus} className={s.magnifyIcon} />
        {showHint && <TypeMore />}
        {searchInputValue && (
          <ClearButton onClick={this.onClear} className={s.clearButton} />
        )}
      </div>
    );
  }
}

SearchField.propTypes = {
  getSearchResult: PropTypes.func,
  clearSearchResult: PropTypes.func,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  rawSearchInputValue: PropTypes.string,
  setSearchInputValue: PropTypes.func,
  searchInputValue: PropTypes.string,
  mealType: PropTypes.string,
  maxResult: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minCharacters: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clearSearchInputValue: PropTypes.func,
  isFoodstuffFilter: PropTypes.bool,
  isFeatured: PropTypes.bool,
  isRecipeFilter: PropTypes.bool,
  product: PropTypes.string
};

export default SearchField;
