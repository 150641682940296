import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const VisaIcon = ({ className }) => (
  <svg
    className={cx({ [className]: className })}
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="25"
    viewBox="0 0 42 25"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-541 -533) translate(397 533.519) translate(144)">
            <rect width="42" height="23.756" fill="#1A1F71" rx="2" />
            <path
              fill="#FFF"
              d="M21 7.919l-2.001 8.908h-2.42L18.58 7.92H21zm9.947 5.939l1.403-2.97.808 2.97h-2.21zm3.247 2.97h2.28l-1.99-8.91H32.38c-.473 0-.872.258-1.05.654l-3.697 8.255h2.588l.514-1.33h3.163l.297 1.33zm-5.927-2.949c.01-2.274-3.594-2.4-3.569-3.415.008-.31.345-.638 1.08-.722.366-.042 1.37-.074 2.512.386l.447-1.827c-.613-.195-1.402-.382-2.382-.382-2.518 0-4.29 1.17-4.305 2.848-.015 1.24 1.265 1.932 2.23 2.345.992.422 1.326.693 1.322 1.07-.007.579-.792.834-1.524.844-1.281.017-2.024-.303-2.616-.545L21 16.37c.595.24 1.693.447 2.833.458 2.675 0 4.425-1.157 4.434-2.948zM16.58 7.92l-3.811 8.908h-2.487l-1.875-7.11c-.114-.437-.213-.598-.56-.782-.564-.3-1.498-.583-2.32-.758l.057-.258h4.002c.51 0 .969.332 1.084.908l.991 5.155 2.448-6.063h2.47z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

VisaIcon.propTypes = {
  className: PropTypes.string
};

export default VisaIcon;
