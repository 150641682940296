import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const ShoeIcon = ({ className }) => (
  <svg
    className={cx({ [className]: className })}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.69002 25H1.21002"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.56 28.5H1.17001"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M1.81001 10.27C2.12001 9.82 2.43001 9.59 2.62001 9.36C3.79001 7.91 4.14001 6.93 5.18001 5.46C6.53001 3.57 8.64001 2.12 9.25001 2.53C9.65001 2.8 9.10001 3.61 9.47001 4.86C9.89001 6.32 11.46 7.02 11.81 7.18C11.96 7.25 13.94 8.12 15.77 7.18C17.11 6.49 17.23 5.27 17.89 5.31C18.64 5.36 19.02 6.56 19.75 8.52C19.88 8.87 21.58 13.34 25.04 16.77C25.34 17.07 26.32 18.02 27.63 19.16C27.9 19.39 28.31 19.71 28.9 20.22C29.59 20.82 30.14 21.31 30.38 21.58C31.62 23.01 31.27 26.88 29.01 28.49C27.86 29.3 26.52 29.32 25.35 29.24C23.26 29.09 21.68 28.44 21.19 28.23L21.1 28.19C17.96 26.78 14.53 24.2 14.53 24.2C11.07 21.59 11.07 21.04 8.50001 19.5C7.66001 19 7.45001 18.93 6.92001 18.66C6.92001 18.66 6.48001 18.44 5.87001 18.08C4.35001 17.19 0.730012 15.06 0.880012 12.61C0.950012 11.55 1.73001 10.42 1.82001 10.28L1.81001 10.27Z"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M2.88 10.23C3.35 10.79 4.1 11.61 5.11 12.5C7.31 14.45 9.14 15.29 10.61 16.13C12.54 17.24 12.78 17.72 14.31 19.14C15.07 19.84 15.88 20.55 17.6 21.88C20.03 23.77 21.24 24.71 22.6 25.37C23.94 26.02 26.39 26.9 30.06 26.6"
      stroke="black"
      strokeWidth="1.49"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.5 21.5L17.8635 20.9184C19.266 18.6744 21.2025 16.8129 23.5 15.5V15.5"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14.5 18.5L15.3888 17.2557C16.4578 15.759 17.7282 14.417 19.164 13.2676L20.36 12.31"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

ShoeIcon.propTypes = {
  className: PropTypes.string
};

export default ShoeIcon;
