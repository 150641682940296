import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const VippsLogoIcon = ({ className }) => (
  <svg
    className={cx({ [className]: className })}
    xmlns="http://www.w3.org/2000/svg"
    width="53"
    height="14"
    viewBox="0 0 53 14"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#FF5B24">
        <g>
          <g>
            <path
              d="M52 3.119C51.415.886 49.994 0 48.055 0c-1.571 0-3.543.886-3.543 3.02 0 1.379.953 2.462 2.507 2.742l1.47.262c1.004.18 1.288.558 1.288 1.067 0 .574-.618.903-1.538.903-1.203 0-1.955-.427-2.072-1.625l-2.123.328c.334 2.314 2.407 3.267 4.279 3.267 1.771 0 3.66-1.018 3.66-3.07 0-1.395-.853-2.413-2.44-2.709l-1.621-.295c-.903-.164-1.204-.607-1.204-1.034 0-.542.585-.886 1.387-.886 1.02 0 1.739.344 1.772 1.477L52 3.119zM4.814 6.63L7.02.246h2.59L5.767 9.717H3.844L0 .247h2.59L4.815 6.63zM18.37 2.955c0 .755-.601 1.28-1.304 1.28-.701 0-1.303-.525-1.303-1.28 0-.756.602-1.28 1.303-1.28.703 0 1.304.524 1.304 1.28zm.401 3.348c-.869 1.116-1.788 1.887-3.41 1.888-1.654 0-2.941-.985-3.944-2.43-.401-.59-1.02-.722-1.47-.41-.419.296-.519.92-.134 1.461 1.387 2.085 3.309 3.299 5.548 3.299 2.056 0 3.661-.985 4.914-2.626.468-.607.452-1.231 0-1.576-.417-.328-1.036-.213-1.504.394zm5.768-1.346c0 1.937 1.136 2.955 2.406 2.955 1.204 0 2.44-.952 2.44-2.955 0-1.97-1.236-2.922-2.423-2.922-1.287 0-2.423.903-2.423 2.922zm0-3.397V.262H22.18V13h2.357V8.47c.785 1.05 1.805 1.494 2.958 1.494 2.156 0 4.262-1.675 4.262-5.122 0-3.3-2.19-4.842-4.062-4.842-1.487 0-2.507.673-3.159 1.56zm11.316 3.397c0 1.937 1.136 2.955 2.406 2.955 1.204 0 2.44-.952 2.44-2.955 0-1.97-1.236-2.922-2.423-2.922-1.287 0-2.424.903-2.424 2.922zm0-3.397V.262h-2.357V13h2.356V8.47c.786 1.05 1.806 1.494 2.959 1.494 2.156 0 4.262-1.675 4.262-5.122 0-3.3-2.19-4.842-4.062-4.842-1.487 0-2.507.673-3.158 1.56z"
              transform="translate(-574 -155) translate(397 149.464) translate(177.97 6)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

VippsLogoIcon.propTypes = {
  className: PropTypes.string
};

export default VippsLogoIcon;
