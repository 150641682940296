import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const SupportIcon = ({ className }) => (
  <svg
    className={cx({ [className]: className })}
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.51997 60.52C4.91997 60.52 4.43997 60.14 4.35997 59.62C4.05997 57.56 3.85997 52.92 7.53997 49.68C8.15997 49.12 8.99997 48.54 10.1 48C10.74 48.28 12.24 48.86 14.22 48.76C15.66 48.68 16.76 48.28 17.36 48C18.56 48.26 19.44 48.68 20.1 49.1C24.26 51.76 23.88 57.44 23.54 59.64C23.46 60.16 22.96 60.52 22.38 60.52C16.76 60.52 11.14 60.52 5.51997 60.5V60.52Z"
      stroke="#026F5E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.24 3.82001H14.24C10.94 3.82001 8.28003 6.46001 8.28003 9.72001V16.66C8.28003 19.92 10.94 22.56 14.24 22.56H16.14C16.3 22.56 16.44 22.7 16.44 22.86L16.36 28.92C16.36 29.18 16.66 29.3 16.84 29.14L24.26 22.64C24.26 22.64 24.38 22.56 24.46 22.56H33.24C36.54 22.56 39.2 19.92 39.2 16.66V9.72001C39.2 6.46001 36.54 3.82001 33.24 3.82001V3.82001Z"
      stroke="#026F5E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.4399 27.48V32.02C29.4399 35.28 32.0999 37.92 35.3999 37.92H44.1799C44.1799 37.92 44.3199 37.94 44.3799 38L51.7999 44.5C51.9799 44.66 52.2799 44.52 52.2799 44.28L52.2199 38.22C52.2199 38.06 52.3399 37.92 52.5199 37.92H54.4199C57.7199 37.92 60.3799 35.28 60.3799 32.02V25.08C60.3799 21.82 57.7199 19.18 54.4199 19.18H44.1199"
      stroke="#026F5E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="14" cy="40" r="5" stroke="#026F5E" strokeWidth="2" />
  </svg>
);

SupportIcon.propTypes = {
  className: PropTypes.string
};

export default SupportIcon;
